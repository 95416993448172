.custom-input,
.custom-select .ant-select-selector {
  width: 100%;
  border-radius: 4px;
  height: 40px;
}

.input_number_type {
  width: 100%;
  border-radius: 6px;
}

.custom-select .ant-select-selector {
  display: flex;
  align-items: center;
}

.ant-select-selector {
  height: 40px !important;
  display: flex;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
}

.ant-form-item {
  margin-bottom: 10px;
}

.scrollable-modal-content {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 15px;
}


.filter_dob {
  height: 40px !important;
  display: flex;
  align-items: center;
  border-radius: 4px;

}

.card-profile-head {
  margin: 5px 24px 24px !important;
}

.user_add_form_details {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px 0;
  text-align: right;
  margin-top: 15px;
}

.ant-divider.ant-divider-horizontal {
  margin: 0px !important;
}

.layout-dashboard .ant-layout-sider.sider-primary {
  margin: 0;
  background: #fff !important;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item {
  margin: 7px 0;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item a .label {
  white-space: break-spaces;
  flex: 1;
  display: flex;
}

.ant-input-affix-wrapper {
  padding: 0px 11px;
  border-radius: 7px;
}

/* .ant-input{    height:40px;} */
.PhoneInputs .ant-input-wrapper .ant-input-group-addon:last-child {
  padding: 0;
  border: 0;
}

.ant-input-number-input {
  height: 38px;
}

.ant-picker {
  border-radius: 6px;
  width: 100%;
}

.ant-picker-input>input {
  height: 30px;
}

.mb-0 {
  margin-bottom: 0;
}

.m-0 {
  margin: 0 !important;
}

.ant-btn-default span {
  margin-left: 0 !important;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: #fff !important;
}

.search-add-button {
  margin-left: 10px;
  /* Adjust the value as needed */
}

.custom-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
}


.InvoiceLeft {
  background: #ffe8ba;
  border: solid 2px #983436;
  border-radius: 20px;
  display: block;
  margin-top: 7px;
  color: #b24b4c;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  padding: 5px 0;
}

.invoiceTopHead h2 {
  color: #7d3b3c;
  font-weight: 600;
  font-size: 17px;
}

.invoiceTopHead h1 {
  color: #974c50;
  font-size: 25px;
}

.invoiceTopHead h3 {
  color: #3a3438;
  font-size: 14px;
  font-weight: 600;
}

.invoiceTopHead h4 {
  color: #3a3438;
  font-weight: normal;
  font-size: 13px;
}

.InvoiceForm h5 {
  font-weight: normal;
  color: #5e5e5e;
  display: flex;
  width: 100%;
  font-size: 17px;
}

.InvoiceForm h5 span {
  padding-left: 0;
  border-bottom: dashed 1px #9b9b9b;
  flex: 1;
  display: flex;
  margin-left: 10px;
  color: #000;
  font-weight: 600;
}

.rupeesBox {
  border: solid 1px #ccc;
}

.rupeesBox i {
  background: #fada99;
  color: #b04e4f;
  padding: 0px 15px;
  display: inline-block;
  font-size: 30px;
}

.Invoicelast h4 {
  color: #002159;
  margin: 10px 0;
  font-size: 14px;
}

.Invoicelast .InvoiceForm h5 {
  justify-content: center;
}

.Invoicelast .InvoiceForm h5 span {
  flex: unset;
  font-size: 10px;
}

.inVoiceFooter {
  background: #fada99;
  padding: 14px 20px;
}

.inVoiceFooter h5 {
  margin: 0;
  font-size: 11px;
  position: relative;
  padding-left: 15px;
}

.inVoiceFooter h5:after {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #a5684b;
  content: '';
  top: 4px;
  left: 3px;
}

.inVoiceheader h5 {
  color: #974c50;
  font-size: 10px;
}

.invoiceTopHead h5 {
  color: #974c50;
  font-size: 14px;
}

.inVoiceheader {
  margin-bottom: 20px;
}

.ant-input {
  font-size: 13px !important;
  font-weight: normal;
}

.ant-input::placeholder {
  font-size: 12px;
}

.ant-form-item-label>label {
  font-size: 13px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px;
}

.tablespace .ant-input-prefix span {
  color: #adaaaa;
}

.ModalTable.ant-descriptions .ant-descriptions-row>td,
.ModalTable.ant-descriptions .ant-descriptions-row>th {
  padding: 7px 24px;
}

.anticon-phone {
  transform: rotate(90deg);
}

.occansanscheck.custom-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
}

.occansanscheck .ant-checkbox+span {
  font-size: 12px;
}

.occansanscheck .ant-checkbox {
  top: 0.35em;
}

.occansanscheck {
  position: relative;
  top: -10px;
}

.ant-card.criclebox .addpaymentTable table th {
  background: #fafafa !important;
  color: #000;
  padding: 10px 10px;
  font-weight: 600;
}

.ant-card.criclebox .addpaymentTable table td {
  padding: 7px 10px;
  font-size: 13px;
}

.ant-card.criclebox .addpaymentTable .ant-btn {
  height: 33px;
  padding: 0 10px;
  line-height: 32px;
  box-shadow: none;
  font-weight: 500;
}

.ant-card.criclebox .addpaymentTable .ant-btn>.anticon+span,
.ant-card.criclebox .addpaymentTable .ant-btn>span+.anticon {
  margin-left: 0;
}

.ant-card.criclebox .addpaymentTable table tr:hover {
  background: #fafafa !important
}



.ant-menu-submenu {
  padding-left: 24px;
  /* Adjust padding if needed */
}

.ant-menu-submenu-popup {
  position: absolute !important;
  top: 0;
  left: 100%;
  z-index: 1050;
  /* Ensure it appears on top */
}

.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 10px !important;
  /* Ensure correct padding */
}

.ant-menu-item {
  border-radius: 0px !important;
  margin: 0px !important;
  width: 100% !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  padding-left: 32px;
}

.ant-menu.ant-menu-sub.ant-menu-inline li {
  font-size: 12px;
}

.content-full-width .content-ant {
  margin-left: 0;
  width: 100%;
}

.content-with-sidebar .content-ant {
  margin-left: 250px;
  width: calc(100% - 250px);
}

/* In your CSS file */
.auto-complete-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.auto-complete-input {
  width: 100%;
}

/* Adjustments for larger screens */
@media (min-width: 600px) {
  .auto-complete-input {
    width: 80%;
    /* Adjust as needed */
  }
}

@media (min-width: 1024px) {
  .auto-complete-input {
    width: 60%;
    /* Adjust as needed */
  }
}

.filterReportCheckbox {
  border-left: 1px solid #d9d9d9;
  background-color: #f5f5f5;
  padding: 9px 11px !important;
  margin: 0 0px !important;
}

.CustomFilterCheckboxSet {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.CustomInputSet {
  flex: 1;
  border: none
}
.amount-range-input {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px;
  background-color: #fff;
}

.amount-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 4px;
}

.range-separator {
  margin: 0 8px;
  color: #999;
}
